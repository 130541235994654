/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        //Librería para hacer swipe en sliders
        (function($){$.fn.touchwipe=function(settings){var config={min_move_x:20,min_move_y:20,wipeLeft:function(){},wipeRight:function(){},wipeUp:function(){},wipeDown:function(){},preventDefaultEvents:true};if(settings)$.extend(config,settings);this.each(function(){var startX;var startY;var isMoving=false;function cancelTouch(){this.removeEventListener('touchmove',onTouchMove);startX=null;isMoving=false}function onTouchMove(e){if(config.preventDefaultEvents){e.preventDefault()}if(isMoving){var x=e.touches[0].pageX;var y=e.touches[0].pageY;var dx=startX-x;var dy=startY-y;if(Math.abs(dx)>=config.min_move_x){cancelTouch();if(dx>0){config.wipeLeft()}else{config.wipeRight()}}else if(Math.abs(dy)>=config.min_move_y){cancelTouch();if(dy>0){config.wipeDown()}else{config.wipeUp()}}}}function onTouchStart(e){if(e.touches.length==1){startX=e.touches[0].pageX;startY=e.touches[0].pageY;isMoving=true;this.addEventListener('touchmove',onTouchMove,false)}}if('ontouchstart'in document.documentElement){this.addEventListener('touchstart',onTouchStart,false)}});return this}})(jQuery);
        
		//Enlaces de noticias personalizadas
        $(".Box-last-new-index").click(function(event) {
          //console.log("target = " + event.target.tagName + ", this=" + this.tagName);
          if(this.tagName === 'DIV'){
            //console.log('Es el div');
            var enlace = $(this).find('a[rel=bookmark]');
            var href = enlace.attr('href');
            window.location.href = href;
          }
        });

        //Buscador
		var ancho_general = 270;
		var restar_ancho_reponsive = 20;
		var minimo_responsive = 500;
        function showBoxSearch(){
			console.log('menu');
			console.log($(window ).width());
			
			if($('#boxBuscador').css('left') === '10px'){
				if($(window ).width() < minimo_responsive){
					ocultar = '-'+$(window ).width()-restar_ancho_reponsive;
				}else{
					ocultar = '-'+ancho_general;
				}
				$("#boxBuscador").animate({left: ocultar}, 'slow', function(){
					//$(".toggleMenu.ClosetoggleMenu").css('display','inline-block');
					$('#activeBoxsearch').animate({visibility: 'visible'}, 'fast', function(){
						
					});						
				});			
			}else{
				//Si es móvil,hacemos más ancho
				if($(window ).width() < minimo_responsive){
					$("#boxBuscador").css('left','-'+$(window ).width()-restar_ancho_reponsive);
					$("#boxBuscador").css('width',$(window ).width()-restar_ancho_reponsive);
				}
				$('#activeBoxsearch').animate({visibility: 'hidden'}, 'fast', function(){
					$('#boxBuscador').animate({left: 10},'slow',function(){              
						$('#search_query_top').focus();
					});
				}); 
				
			}
        }

        $('body').ready(function() {
         /*$("#carouselIndex1").touchwipe({
               wipeLeft: function()  { $("#carouselIndex1").carousel('next'); },
               wipeRight: function() { $("#carouselIndex1").carousel('prev'); },
               min_move_x: 20,
               min_move_y: 20,
               preventDefaultEvents: true
          });
          $("#carouselLachufa").touchwipe({
               wipeLeft: function()  { $("#carouselLachufa").carousel('next'); },
               wipeRight: function() { $("#carouselLachufa").carousel('prev'); },
               min_move_x: 20,
               min_move_y: 20,
               preventDefaultEvents: true
          });
          $("#carouselProduct").touchwipe({
               wipeLeft: function()  { $("#carouselProduct").carousel('next'); },
               wipeRight: function() { $("#carouselProduct").carousel('prev'); },
               min_move_x: 20,
               min_move_y: 20,
               preventDefaultEvents: true
          });*/
          $( ".carousel" ).each(function() {

            var elemento_slider = $(this);
            $( elemento_slider ).touchwipe({
                 wipeLeft: function()  { $(elemento_slider).carousel('next'); },
                 wipeRight: function() { $(elemento_slider).carousel('prev'); },
                 min_move_x: 20,
                 min_move_y: 20,
                 preventDefaultEvents: false
            });
          });
          //Cuando pierde foco el buscador se escocnde.
          $('#search_query_top').focusout(function(){
            //showBoxSearch();
          });
          //Activa evento al hacer click sobre la lupa.
          $(".activeBoxsearch").click(function(e) {
            showBoxSearch();
          });
		  //Activa evento al hacer click sobre la x del buscador.
          $(".hideBoxsearch").click(function(e) {
            showBoxSearch();
          });
        });

        //Menú
        function showHiddenLevelMenu(elemento, elementosHermanos){
          var menu_ul = elemento.find('+ul');
          if(elemento.hasClass('pulsado')){
            elemento.removeClass('pulsado');
          }else{
            elemento.addClass('pulsado');
          }
          if(menu_ul.hasClass('click')){
            menu_ul.removeClass('click');
            menu_ul.toggle();
            return;
          }
          $(''+elementosHermanos).css({"display":"none"});
          $(''+elementosHermanos).removeClass('click');
          menu_ul.addClass('click');
          menu_ul.toggle();
        }

        $('body').ready(function() {
          //Botón show/hidden Menú
          $(".toggleMenu").click(function(e) {
            e.preventDefault();
            ww = document.body.clientWidth;
            //version móvil y tabler
            if(ww < 992){
				
				if($("#MenuPrincipal").css('display') == 'none'){
					$("#MenuPrincipal").slideDown();
				}else{
					$("#MenuPrincipal").slideUp();
				}
				//$("#MenuPrincipal").toggle();
				$(".nav-primary .menu-item").show();
				if($(".toggleMenu.ClosetoggleMenu").css('display') == 'none'){
					$(".toggleMenu.OpentoggleMenu").animate({opacity: 0}, 'slow', function(){
						$(".toggleMenu.OpentoggleMenu").css('display','none');	
						$(".toggleMenu.ClosetoggleMenu").css({'display':'inline-block'},{'opacity':'0'});
						$(".toggleMenu.ClosetoggleMenu").animate({opacity: 1}, 'slow', function(){
						//$(".toggleMenu.ClosetoggleMenu").css('display','inline-block');						
						});
					});
				
				}else{
					$(".toggleMenu.ClosetoggleMenu").animate({opacity: 0}, 'slow', function(){
						$(".toggleMenu.ClosetoggleMenu").css('display','none');	
						$(".toggleMenu.OpentoggleMenu").css({'display':'inline-block'},{'opacity':'0'});
						$(".toggleMenu.OpentoggleMenu").animate({opacity: 1}, 'slow', function(){
							//$(".toggleMenu.OpentoggleMenu").css('display','inline-block');						
						});
					});
				//$(".toggleMenu.ClosetoggleMenu").css('display','none');
				}
              //$(".toggleMenu").toggle();
            }else{
              //versión web
              //$(".nav-primary .menu-item").toggle();
				if($(".nav-primary .menu-item").hasClass('invi')){
					$(".nav-primary .menu-item").animate({opacity: 1}, 'slow', function(){
						$(".nav-primary .menu-item").removeClass('invi');
					});
				}else{
					$(".nav-primary .menu-item").animate({opacity: 0}, 'slow', function(){
						$(".nav-primary .menu-item").addClass('invi');
					});
				}
				//$(".nav-primary .menu-item").toggleClass('invisible', 2000);

				//$(".nav-primary .menu-item").css({'visibility':'hidden'});
				if($(".toggleMenu.ClosetoggleMenu").css('display') == 'none'){
					$(".toggleMenu.OpentoggleMenu").animate({opacity: 0}, 'slow', function(){
						$(".toggleMenu.OpentoggleMenu").css('display','none');	
						$(".toggleMenu.ClosetoggleMenu").css({'display':'inline-block'},{'opacity':'0'});
						$(".toggleMenu.ClosetoggleMenu").animate({opacity: 1}, 'slow', function(){
							//$(".toggleMenu.ClosetoggleMenu").css('display','inline-block');						
						});
					});
					
				}else{
					$(".toggleMenu.ClosetoggleMenu").animate({opacity: 0}, 'slow', function(){
						$(".toggleMenu.ClosetoggleMenu").css('display','none');	
						$(".toggleMenu.OpentoggleMenu").css({'display':'inline-block'},{'opacity':'0'});
						$(".toggleMenu.OpentoggleMenu").animate({opacity: 1}, 'slow', function(){
							//$(".toggleMenu.OpentoggleMenu").css('display','inline-block');						
						});
					});
					//$(".toggleMenu.ClosetoggleMenu").css('display','none');
				}
				
				/*
				if($(".toggleMenu.OpentoggleMenu").css('display') == 'none'){
					$(".toggleMenu.OpentoggleMenu").css('display','inline-block');
				}else{
					$(".toggleMenu.OpentoggleMenu").css('display','none');
				}*/
				//$(".toggleMenu").toggle();
            }
          });

          //Despiece de Menú segundo nivel
          $(".nav-primary #menu-principal-ul>.menu-item.drop-hover>a").click(function(e) {
            showHiddenLevelMenu($(this), '.nav-primary #menu-principal-ul>.menu-item.drop-hover>a+ul');
          });

          //Despiece de Menú tercer nivel
          $(".nav-primary .sub-menu .menu-item.drop-hover > a").click(function(e) {
             showHiddenLevelMenu($(this), '.nav-primary .sub-menu .menu-item.drop-hover > a+ul');
          });
        });

        //instagram
        $('body').ready(function() {
          if($('#Box-instagram').length){
            var feed = new Instafeed({
                get: 'user',
                target : "list-instagram",
                resolution : "standard_resolution",
                sortBy : "most-recent",
                limit : 4,
                userId: '520415274',
                accessToken: '520415274.1677ed0.51b7b640cac44b718cc13cceceb5a4a4',
                template: '<div class="col-6 col-md-6 col-lg-3"><div class="item-list-instagram"><a target="_blank" href="{{link}}"><img class="item-list-instagram-img img-fluid" src="{{image}}" /><img class="item-list-instagram-icon" src="'+img_instagram+'"/></a></div></div>'
            });
            feed.run();
          }
        });

        //Scroll Menú
        $('body').ready(function() {
          var $win = $(window);
          var $pos = 140;
          $win.scroll(function () {
             if ($win.scrollTop() <= $pos){
               $('.menu-logo').removeClass('height-small');
             }else {
               $('.menu-logo').addClass('height-small');
             }
          });
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
